<template>
  <v-container
    fluid
  >
    <app-api-info
      :api-info="apiError"
      class="pb-3 mx-1"
    />
    <basic-card
      ref="iframeParent"
      v-resize="onResize"
      title="$l.paths.multimedia"
      class="mt-9"
    >
      <div
        v-if="loading"
        class="text-center"
      >
        <v-progress-circular

          :size="40"
          :width="7"
          color="success"
          indeterminate
        />
      </div>
      <v-row
        v-else
        v-cloak
        dense
        class="justify-center"
      >
        <v-col
          v-for="(video, i) in videos"
          :key="i"
          class="text-center"
        >
          <iframe
            width="448"
            height="252"
            :style="{'max-width':videoMaxWidth}"
            :src="video.url"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </v-col>
        <v-col
          v-if="!videos"
          class="text-center"
        >
          {{ $i18n.t("$l.app.noDataAvailable") }}
        </v-col>
      </v-row>
    </basic-card>
  </v-container>
</template>

<script>

export default {
  name: 'MultimediaWrapper',
  data () {
    return {
      videoMaxWidth: '400px',
      videos: null,
      loading: false,
      apiError: null
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.onResize()
  },
  methods: {
    onResize () {
      this.videoMaxWidth = (this.$refs.iframeParent.$el.clientWidth - 32) + 'px'
    },
    loadData () {
      this.loading = true
      this.apiError = null
      this.$xapi.get('overview/multimedia')
        .then(r => {
          this.videos = r.data
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
