<template>
  <MultimediaWrapper />
</template>

<script>
import MultimediaWrapper from '@/components/multimedia/MultimediaWrapper'

export default {
  name: 'Multimedia',
  components: {
    MultimediaWrapper
  }
}
</script>
